.workflow-tab {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px 0px;
}

.veam-workflow-tab {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px 0px;
  height: 100%;
}
