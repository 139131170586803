.bom-modal .mantine-Modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.bom-modal .mantine-Modal-content {
  background-color: #fff;
  color: black;
  width: 70vw;
  flex: none !important;
}

.bom-modal caption {
  margin-bottom: 0;
  padding: 1rem 0 0 0;
  color: #e5b611;
  font-weight: 600;
  font-size: 1rem;
}
