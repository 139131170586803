.sku-relations-modal .mantine-Modal-content {
  color: black;
  height: 30rem;
  width: 40rem;
  flex: none !important;
}

.sku-relations-modal .mantine-Modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.sku-relations-modal button.rct-collapse.rct-collapse-btn {
  margin-right: 1rem;
}

.sku-relations-modal li {
  margin-bottom: 0.3rem;
}
.sku-relations-modal ol {
  margin-top: 0.5rem;
}

.sku-relations-modal .rct-node .rct-node-parent:nth-child(1) {
  padding-top: 1rem;
}

.sku-relations-modal input[type='checkbox'] {
  margin-right: 0.5rem;
}

.sku-relations-modal li.rct-node.rct-node-parent.rct-node-expanded {
  padding-top: 0.5rem;
}

.sku-relations-modal button.rct-collapse.rct-collapse-btn {
  outline: none;
  border: 1px;
}

.sku-relations-modal span.rct-text {
  margin-top: 1rem;
}
