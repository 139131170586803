input[type='radio'] {
  outline: 2px solid rgb(0, 0, 0);
}

input[type='radio']:checked,
input[type='radio']:focus {
  outline: 2px solid rgb(238, 184, 49);
  border-color: rgb(238, 184, 49);
  background: none;
}

.page-add-new-rfs .accordion-control {
  padding: 8px !important;
}

.page-add-new-rfs .mantine-Radio-radio {
  width: 12px !important;
  height: 12px !important;
}

.page-add-new-rfs .mantine-Radio-radio {
  background: none;
}

.page-add-new-rfs .mantine-Text-root {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.page-add-new-rfs .mantine-RadioGroup-radio:checked {
  width: 12px;
  height: 12px;
}
.mantine-8bj6bh:disabled {
  color: black;
  font-weight: 800;
}
