.toggle-modal .mantine-Modal-content {
  background-color: #fff;
  color: black;
  height: 17rem;
  width: 40rem;
  flex: none !important;
}

.toggle-modal .mantine-Modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.toggle-modal .modal-content {
  margin: 2rem 0rem;
  border: none !important;
}

.toggle-modal .mantine-Checkbox-root {
  align-items: flex-start;
}

.toggle-modal .mantine-Checkbox-inner {
  margin-top: 0.15rem;
}
