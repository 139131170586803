.droparea {
  background-color: #030303;
}
.mantine-Tabs-tabLabel {
  color: black;
}

.all-rfs-tabs .mantine-Tabs-tab.subTab .mantine-Tabs-tabLabel {
  color: black
}
.buttonRow {
  padding-top: 10em;
}
.mantine-Dropzone-root {
  height: 10em;
}
.dropImage {
  max-width: fit-content;
  max-height: 8vh;
}

.projectNameComp {
  padding-bottom: 0.5em;
}
.dropHeader {
  font-size: 16px;
  font-weight: bold;
  padding-top: 0.5em;
}
.mantine-Group-root.droparea {
  min-height: 100% !important;
}

.DropZone_root .mantine-Dropzone-inner {
  height: 100%;
}

@media only screen and (max-width: 1016px) {
  .back {
    width: 100px !important;
    font-size: 1vh;
  }
  .save {
    width: 100px !important;
    font-size: 1vh;
  }
  .next {
    width: 100px !important;
    font-size: 1vh;
  }
}
