.mantine-1euen2d.mantine-Tabs-body {
  visibility: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #1b1f85;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
footer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

input[type='checkbox']:checked {
  background: black !important;
}

.table-checkbox {
  accent-color: black !important;
}

/*.mantine-Switch-input {
  background-color: rgb(0, 0, 0) !important;
}*/
.mantine-Switch-trackLabel {
  color: rgb(0, 0, 0) !important;
}
.table-container {
  padding: 0px;
  margin: 0px;
  zoom: 85%;
  overflow: auto;
}

.exportToCsv-container {
  display: flex;
  flex-direction: row-reverse;
}

a {
  text-decoration: none !important;
}

.rct-node {
  padding-top: 0px;
}

.disabled-link {
  pointer-events: none;
  cursor: pointer;
}

.rfs-type-disabled {
  opacity: 0.2;
}

.disabled-accordion .mantine-Accordion-contentInner {
  display: none;
}

.disabled-accordion {
  opacity: 0.5;
}

.mantine-Modal-close:focus {
  outline: none;
}
.prod-dev-tabs .mantine-Tabs-tabActive .mantine-Tabs-tabLabel {
  color: white;
}

.imp-feas-accordion .mantine-Accordion-label {
  font-size: 14px;
}

.logout-button:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.matDetailsTable {
  padding: 2em;
  background-color: #f0f0f0;
}
.rowHeader {
  background-color: white;
}
th,
td {
  white-space: nowrap;
}
.gplanstartdate {
  /* display: none; */
}

.page-open_sku_to_market .gplanstartdate {
  display: none;
}

.production-select-plant {
  display: flex;
  align-items: center;
  margin-left: 14px;
}

.production-select-plant label {
  width: 100px;
}

.error-button {
  color: red !important;
  border: 1px solid red;
  border-color: red !important;
}
.page-scopeunlock .linear-input {
  display: inline-flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-right: 40rem;
}

.page-scopeunlock .form-container .mantine-MultiSelect-label,
.page-scopeunlock .form-container .mantine-TextInput-label,
.page-scopeunlock .form-container .mantine-Textarea-label,
.page-scopeunlock .form-container .mantine-Select-label {
  font-weight: 500;
  font-size: 20px;
  width: 30rem;
  padding-left: 3rem;
  display: inline-flex;
}

.page-scopeunlock .form-container {
  margin-top: 1rem;
}
.page-scopeunlock .form-container input,
.page-scopeunlock .form-container textarea,
.page-scopeunlock .form-container .mantine-MultiSelect-input {
  width: 30rem !important;
}

.page-scopeunlock .sku-table {
  margin-top: 2rem;
}

.submit_button_trials:disabled {
  color: grey;
  border: #282c34;
}

.submit_button_trials {
  color: white;
}

.imp-feasiblity-table td {
  text-align: left !important;
}

.fullscreen .custom-paper {
  width: 98.5vw !important;
}

.confirm-modal .mantine-Modal-title {
  margin: 12px !important;
}

.custom-table :first-child :nth-child(2) {
  min-height: unset;
}

/* .stm-table .mantine-TableHeadCell-Content {
  justify-content: space-between !important;
} */

.mantine-Select-dropdown {
  z-index: 500;
}

.add-supplier-button:disabled,
.custom-mantine-button:disabled {
  opacity: 0.5;
}

.pointer {
  cursor: pointer;
}

.custom-paper {
  position: relative;
}

.dropdown-item.active {
  background-color: #e3af32 !important;
}

.dropdown-item:active {
  background-color: lightgrey !important;
}

.Mainparent:hover {
  border-radius: 20px;
}
