.color-nav {
  background-color: #1a1b1e;
  height: 72px;
}

.navlink {
  color: white !important;
  padding-left: 100px;
}
.me-auto {
  margin-right: 0;
}
.nav-link {
  margin-left: 1em;
}
.d-flex {
  margin-right: auto;
}

.color-nav .form-control {
  color: white;
}

.color-nav .form-control:focus {
  color: white;
  box-shadow: none;
}

/* .user{
  left:100%;
  right: 0px;
}

.nav-dropdown{
  right:0%;
} */

.spacer {
  flex-grow: 1;
}

.projects-dropdown {
  margin-right: 20px; 
  margin-bottom: 06px; 
}

/* Additional styles for the dropdown and other elements if needed */
.navbar {
  display: flex;
  align-items: center;
}

.navbar .navlink {
  margin-left: 20px; /* Adjust as needed */
}


@media only screen and (max-width: 995px) {
  .me-auto {
    visibility: collapse !important;
  }
  .searchbar {
    margin-right: 0vw !important ;
    width: 5vw !important;
    visibility: collapse !important;
  }
  .avatar {
    margin-left: auto !important;
  }
}
