.matTable {
  overflow-x: scroll;
}
.searchWrapper {
  border: 0 !important;
  border-radius: 12px !important;
}

.mantine-Accordion-content {
  padding: 0;
}
