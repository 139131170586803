.navItem {
  padding: 1rem 0.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navItem:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.activeNavItem {
  background-color: #000 !important;
}

.navIcon {
  color: #d1d5db;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
}

.navLabel {
  color: #d1d5db;
  font-size: 1rem;
}

.navItemHeaderButton {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.navItemHeaderChevron {
  color: #d1d5db;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  transition: all 0.25s;
}

.chevronExpanded {
  transform: rotate(180deg);
}

.navChildrenBlock {
  background-color: hsl(0, 0%, 16%);
}
@media only screen and (max-width: 990px) {
  .color-nav {
    visibility: hidden !important;
  }
}
