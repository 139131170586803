.imp-feasibility-modal .mantine-Modal-title {
  color: #e5b611;
  font-weight: 500;
  font-size: 1.2rem;
}

.imp-feasibility-modal .caption {
  font-weight: 400;
  font-size: 0.9rem;
  color: black;
}
