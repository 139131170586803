.users-modal .mantine-Input-input {
  border-radius: 1rem;
}

/* .mantine-Modal-inner	{
    background-color:#262626;
    color:white
}  */
.users-modal .mantine-Modal-content {
  background-color: #fff;
  color: black;
  height: 35rem;
  width: 50rem;
  flex: none !important;
}
