.fast-track-modal .mantine-Modal-content {
  background-color: #fff;
  color: black;
  height: 29rem;
  width: 40rem;
  flex: none !important;
}

.fast-track-modal .mantine-Modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.fast-track-modal .modal-content {
  margin: 2rem 0rem;
  border: none !important;
}
