.disclaimer-modal .mantine-Modal-content {
  background-color: #fff;
  color: black;
  width: 30rem;
  flex: none !important;
}

.confirm-modal .mantine-Modal-content {
  background-color: #fff;
  color: black;
  width: 35rem;
  flex: none !important;
}

.pricing-modal .mantine-Modal-content {
  background-color: #fff;
  color: black;
  width: 45rem;
  flex: none !important;
}

.pricing-modal .mantine-Modal-header {
  font-weight: 600;
  font-size: large;
}

.editIcon {
  padding-left: 0.5rem;
  vertical-align: bottom !important;
  cursor: pointer;
}

.disclaimer-modal .mantine-Modal-title,
.confirm-modal .mantine-Modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.disclaimer-modal .modal-content {
  margin: 2rem 0rem;
  border: none !important;
}

.disclaimer-modal .modal-checkbox {
  margin: 1rem 0rem;
}

.disclaimer-modal .mantine-Checkbox-root {
  align-items: flex-start;
}

.disclaimer-modal-group { 
  border: 2px dashed gray;
  border-radius: 0.3rem;
  background-color: #eaeaea;
  padding: 0.5rem;
}

.confirm-modal-group {
  border: 2px dashed green;
  border-radius: 0.3rem;
  background-color: rgba(28, 171, 59, 0.214);
  padding: 0.5rem;
}

.invalid-modal-group {
  border: 2px dashed rgb(236, 110, 101);
  border-radius: 0.3rem;
  background-color: rgba(248, 66, 66, 0.432);
  padding: 0.5rem;
}

.alert-modal-group {
  border: 2px dashed gray;
  border-radius: 0.3rem;
  background-color: #eaeaea;
  padding: 0.5rem;
}

.padded-error {
  background-color: #f8d7da;
  text-align: center;
  color: #721c24;
  padding: 16px;
}

.FixedRight.error {
  white-space: nowrap;
  font-size: 0.9rem;
  color: red;
}
