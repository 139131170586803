.attachmentComp {
    background-color: #f0f0f0;
    border-radius: 1em;
    padding: 2em;
    padding-left: 2em;
    padding-right: 2em;
  }
  .droparea {
    background-color: #eaeaea;
  }
  
  /* .mantine-Tabs-tabLabel {
      color: black;
  } */
  
  .all-rfs-tabs .mantine-Tabs-tabLabel {
    font-weight: 600;
  }
  
  .all-rfs-tabs .mantine-Tabs-tab.subTab .mantine-Tabs-tabLabel {
    color: black
  }
  
  .in-progress-tabs .mantine-Tabs-tabLabel {
    font-size: 12px;
    font-weight: 400;
  }
  
  
  
  .all-rfs-tabs .mantine-Tabs-tabActive .mantine-Tabs-tabLabel {
    color: white;
  }
  
  .all-rfs-tabs .in-progress-tabs .mantine-Tabs-tabActive .mantine-Tabs-tabLabel {
    color: black;
  }
  
  .affected-sku-error .mantine-Tabs-tabLabel {
    color: rgb(250, 2, 2);
  }
  
  .affected-sku-error .mantine-Tabs-tabLabel .mantine-Tabs-tabActive {
    color: #be0303;
    font-weight: 700;
  }
  
  .buttonRow {
    padding-top: 10em;
  }
  .mantine-Dropzone-root {
    height: 10em;
  }
  .dropImage {
    max-width: fit-content;
    max-height: 8vh;
  }
  
  .projectNameComp {
    padding-bottom: 0.5em;
  }
  .dropHeader {
    font-size: 16px;
    font-weight: bold;
    padding-top: 0.5em;
  }
  .mantine-Group-root.droparea {
    min-height: 100% !important;
  }
  