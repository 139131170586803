.editButton {
  padding-bottom: 1rem;
}

.rfs-form-textarea .mantine-Textarea-input {
  height: 8rem !important;
}

.mantine-MultiSelect-label {
  font-weight: 500;
  font-size: 0.875rem;
  color: #212529;
}

.InputField {
  /* padding-top: 0.5em; */
  padding-left: 0px !important;
  padding-right: 0.5em;
  /* padding-bottom: 0.2em; */
}
.mantine-DatePicker-label {
  font-weight: 500;
  font-size: 0.875rem;
  color: #212529;
}
h5 {
  padding: 0.5em 0.5em 0.5em 0.5em;
}

hr {
  width: 50%;
}
.checkbox .mantine-Checkbox-input:checked {
  background-color: rgb(236, 196, 93);
  border-color: #000000;
}
.empty {
  width: 50%;
}
.dropdown {
  margin-top: 0.4em;
}
/* .buttonRow{
    padding: 100px;
} */
.mantine-ScrollArea-thumb {
  background-color: #e5b611;
}

.mantine-Text-root .modalText {
  white-space: nowrap;
}

.checkbox .mantine-Checkbox-label {
  font-weight: 500;
  font-size: 0.875rem;
  color: #212529;
}

.mantine-Textarea-label {
  font-weight: 500;
  font-size: 0.875rem;
  color: #212529;
}
/* .sectionHeadings {
  font-weight: bold;
} */

.capex {
  padding: 0.5em;
}

.mantine-TextInput-input:focus {
  border-color: #e5b611;
}
.mantine-TextInput-input:focus-within {
  border-color: #e5b611;
}
.mantine-Textarea-input:focus {
  border-color: #e5b611;
}
.mantine-Textarea-input:focus-within {
  border-color: #e5b611;
}
@media only screen and (max-width: 780px) {
  .save {
    width: 100px !important;
    font-size: 1vh;
  }
  .next {
    width: 100px !important;
    font-size: 1vh;
  }
}
@media only screen and (max-width: 780px) {
  .delete {
    width: 100px !important;
    font-size: 1vh;
  }
  .submit {
    width: 100px !important;
    font-size: 1vh;
  }
}
