.nthdpg {
    padding: 15px;
    border-radius: 20px;
    background: #E0E0E0;
}

.req {
    color: #FF0000;
}

.lists {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 2px solid #C6C6C7;
}

.lists:first-child {
    border-top: 2px solid #C6C6C7;
}

.lists.selected {
    background: rgb(217, 217, 217);
}

.d-flex {
    display: flex;
}

.justify-content-right {
    justify-content: right;
}

.pos-r {
    position: relative;
}

.pos-a {
    position: absolute;
}

.h-100 {
    height: 100%;
}

.submitBtnWrap {
    right: 15px;
    bottom: 10px;
}

@media only screen and (min-width: 1025px) {
    .accf-modal .mantine-1abbsss {
        width: 40%;
    }
}