.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* random */
}

.login-page .card {
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: #1a1b1e;
  border-radius: 8px;
}

.login-page .card-body {
  color: white !important;
  text-align: center;
}

.login-page .p-4 {
  padding: 1rem !important;
}

.login-page .text-center {
  text-align: center !important;
}

.login-page .mb-6,
.login-page .my-6 {
  margin-bottom: 2rem !important;
}

.login-page .h-6 {
  height: 2rem !important;
}

.login-page img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

.text-center {
  text-align: center !important;
}

.h-9 {
  height: 6rem !important;
}

.card-title {
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.text-center {
  text-align: center !important;
}

.card-title {
  color: golden;
  font-size: 1rem;
}

.content {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
}

.loginForm {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  padding: 0;
  border: 1px solid #6b6b6b;
  max-width: 23%;
  position: relative;
  z-index: 1;
}

.registerForm {
  max-width: 30%;
}

.photo::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(8, 7, 12);
  background: linear-gradient(45deg, rgb(0 0 0) 0%, rgb(13 15 12) 100%);
  background-repeat: no-repeat;
  z-index: -1;
}

.photo {
  position: relative;
}

.grd-txt-yellow {
  background: -webkit-linear-gradient(90deg, #d7a833, #f4df03 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.innerContent {
  width: 100%;
  padding: 3em 0;
}

.innerContent input {
  background: transparent !important;
  color: #fff;
}

.innerContent input:disabled {
  background: #3c3c3c !important;
}

.divider {
  border-color: rgba(255, 255, 255, 0.64);
  width: 65%;
  margin: 25px auto 35px;
}

.innerContent > div {
  position: relative;
  z-index: 1;
}

.innerContent::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.08);
  backdrop-filter: blur(22.5px);
  z-index: 0;
}

@media only screen and (max-width: 1200px) {
  .loginForm {
    max-width: 40%;
  }
}

@media only screen and (max-width: 1000px) {
  .login {
    width: 140px;
    font-size: 1.8vh;
  }
  .register {
    width: 140px;
    font-size: 1.8vh;
  }

  .loginForm {
    width: 100%;
    max-width: none;
  }
}

@media only screen and (max-width: 642px) {
  .login {
    margin-top: 1vh;
    width: 140px;
    font-size: 1.8vh;
  }
  .register {
    margin-top: 1vh;
    width: 140px;
    font-size: 1.8vh;
  }

  .loginForm {
    flex-basis: 90%;
  }
}
