.Mainparent {
  background-color: #ffff;
  border-radius: 20px;
border: 1px solid #e5b611;
}
.project-name .mantine-Accordion-label {
  font-size: x-large;
  font-weight: bold;
  color:#e5b611;

}

.project-name {
  zoom: 75%;
}

.parentA {
  display: inline-flex;
  justify-content: space-between;
}
.cloumnA {
  padding-left: 50px;
}
.cloumnB {
  padding-left: 50px;
}
.cloumnC {
  padding-left: 50px;
}
.cloumnD {
  padding-left: 50px;
}

.project-name p {
  margin-bottom: 0px;
}
