.searchGroup {
  background: transparent;
  padding: 2px 10px 10px 10px;
  border-radius: 2px;
}


.chevrons {
  &[data-rotate] {
    transform: rotate(45deg);
  }
}

.icons {
  width: rem(16px);
  height: rem(16px);
}

.indivisualstepsbutton {
  margin-top: '1.8rem';
  margin-right:  3rem ;
  height:  2.5rem ;
  background:   linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%) ;
  color:  black ;
}