.SkuTable {
  padding: 2em;
  background-color: #f0f0f0;
}

.rowHeader {
  background-color: white;
}

th,
td {
  white-space: nowrap;
}
