.cell-option-1 {
  background-clip: padding-box;
  background-color: #ff9945 !important;
}

.cell-option-2 {
  background-clip: padding-box;
  background-color: #79e344 !important;
}

.cell-option-3 {
  background-clip: padding-box;
  background-color: #79e344 !important;
  border-left: 3px solid red;
}

.column-option-1 {
  background-clip: padding-box;
  background-color: #ccc !important;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #fff;
  border-right: 1px solid white !important;
}

.column-option-2 {
  background-clip: padding-box;
  background-color: #79e344 !important;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #fff;
  border-right: 1px solid white !important;
}

.column-option-3 {
  background-clip: padding-box;
  background-color: red !important;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #fff;
  border-right: 1px solid white !important;
}

.row-option-4 {
  position: relative;
  background-color: rgb(199, 91, 91) !important;
  color: #fff;
  font-size: 14px;
}

.row-option-1 {
  position: relative;
  background-clip: padding-box;
  background-color: #f1f0f3 !important;
}

.row-option-title {
  background-color: #d9d8df !important;
  width: 100%;
  position: absolute;
  /* left: 1px !important; */
  padding-bottom: 14px !important;
  font-size: 16px !important;
  cursor: pointer;
}

.row-option-title-red {
  background-color: #e21d0f !important;
  color: #fff;
  width: 100%;
  position: absolute;
  /* left: 1px !important; */
  padding-bottom: 14px !important;
  font-size: 16px !important;
  cursor: pointer;
}

.row-option-2 {
  background-clip: padding-box;
  background-color: #19aa14 !important;
  width: 100%;
  position: absolute;
  left: 1px !important;
  padding-bottom: 14px !important;
}

.row-option-3 {
  background-clip: padding-box;
  background-color: #e61818 !important;
  color: #fff;
  left: 1px !important;
  padding-bottom: 14px !important;
}

.row-option-5 {
  background-clip: padding-box;
  background-color: #b82121 !important;
  color: #fff;
  width: 100%;
  position: absolute;
  left: 1px !important;
  font-size: 16px !important;
  padding-bottom: 14px !important;
}

.customIndividualStepReport thead th:nth-child(2) {
  display: none !important;
}

.customIndividualStepReport tbody td:nth-child(1) {
  width: 2px !important;
}

.customIndividualStepReport thead th div[title='title'] {
  display: none !important;
}

.hide-column {
  display: none !important;
}

.customIndividualStepReport .mantine-TableBodyCell-DetailPanel {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
