.draft {
  background: linear-gradient(90deg, #e3af32 0%, #f4e00f 100%);
}

.rejectpr,
.draft,
.approvepr {
  width: 180px !important;
}

.rejectpr span,
.approvepr span,
.draft span {
  white-space: break-spaces !important;
}

@media only screen and (max-width: 1600px) {
  .rejectpr,
  .draft,
  .approvepr {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1310px) {
  .rejectpr,
  .draft,
  .approvepr {
    width: 150px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .rejectpr,
  .draft,
  .approvepr {
    width: 120px !important;
    font-size: 12px !important;
  }
}
