.InputField {
  padding: 0.5rem;
}
.checkbox {
  padding: 0.5rem 0;
}
.mantine-Checkbox-input {
  outline: #e5b611;
}
h5 {
  padding: 1rem;
}
.fixedLeft {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 0;
  color: #212529;
  /* padding-bottom: 0.2em; */

  /* font-weight: 600; */
}

.FixedRight .mantine-Text-root {
  padding: 0.5rem;
  font-size: 0.875rem;
  color: #e5b611;
  white-space: nowrap;
}
hr {
  width: 50%;
}
.mantine-Checkbox-input:checked {
  background-color: #000000; 
  border-color: #000000;
}
.empty {
  width: 50%;
}
.dropdown {
  margin-top: 0.4em;
}
/* .buttonRow{
    padding: 100px;
} */
.mantine-ScrollArea-thumb {
  background-color: #e5b611;
}

.sectionHeadings {
  font-weight: bold;
}

.mantine-TextInput-input:focus {
  border-color: #e5b611;
}
.mantine-TextInput-input:focus-within {
  border-color: #e5b611;
}
.mantine-Textarea-input:focus {
  border-color: #e5b611;
}
.mantine-Textarea-input:focus-within {
  border-color: #e5b611;
}
/* .mantine-AppShell-body{
    background-color: #1A1B1E;
} */

.scope-label .mantine-Accordion-label {
  font-size: 14px;
  font-weight: 500;
}

.scope-implementation-label {
  font-size: 1.1rem;
}
