body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}

.accordion-label {
  padding-left: 2px !important;
}

main {
  width: calc(100% - 250px) !important;
  height: 100% !important;
  min-height: auto !important;
}

.mantine-1682jzp.mantine-AppShell-main {
  --mantine-aside-width: 0rem;
}

.accordion-innerContent {
  margin-left: 10px;
}

.users-modal .mantine-Tabs-body,
.page-attachments .mantine-Tabs-body {
  visibility: visible !important;
}

.custom-multi-select .mantine-MultiSelect-values {
  max-width: 150px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sectionHeadings {
  padding: 0;
  color: #e5b611;
  text-transform: uppercase;
  font-size: 22px;
}

.report-page-headings {
  padding: 14px 2px;
  color: #e5b611;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

.section-reportHeadings {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  padding: 6;
  color: #11110f;
  font-size: 26px;
  font-weight: bold;
}

.report-page-headings {
  padding: 14px 2px;
  color: #e5b611;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

.section-reportHeadings {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  padding: 6;
  color: #11110f;
  font-size: 26px;
  font-weight: bold;
}

.full-screen-mode {
  overflow: auto;
  background: #eee;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: 'hidden';
  width: 100vw;
}

.icon-button {
  color: #e5b611;
  outline: none;
  border: none;
}

.mantine-MultiSelect-searchInput:disabled {
  background: transparent !important;
}

.search .mantine-Input-defaultVariant.mantine-Input-input:focus {
  border-color: rgb(245, 224, 3);
}

.mantine-Select-defaultVariant.mantine-Select-input:focus {
  border-color: rgb(245, 224, 3);
}

.mantine-DatePicker-defaultVariant.mantine-DatePicker-input:focus {
  border-color: rgb(245, 224, 3);
}

.mantine-MultiSelect-defaultVariant.mantine-MultiSelect-input:focus-within {
  border-color: rgb(245, 224, 3);
}

table {
  font-size: 14px;
}

.error-label {
  color: #f03e3e;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.disabled-links {
  pointer-events: none;
}

.navbar-collapse {
  width: 85%;
  padding-left: 50px;
}

.activeNavItem {
  background-color: #000 !important;
}

.capitalised {
  text-transform: capitalize;
}

.dropzone-comp {
  display: none;
}

.report-page {
  background-color: #c8cdcf;
}

.report-text {
  padding: 21px 2px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.report-button {
  opacity: 0.8;
  transition: 0.3s;
  padding: 16px 0px;
  align-items: center;
  border: #cdcdcd;
  height: 100%;
  width: 100%;
  background-color: #d5dadd;
}

.report-button:hover {
  opacity: 1;
  background-color: #11110f;
  color: rgb(238 193 21 / 92%);
}

.report-button:hover .report-text {
  color: #d5dadd;
}

.report-button:hover .report-text {
  color: #d5dadd;
}

.page-sku-2 .mantine-TextInput-invalid,
.page-submission-review .mantine-TextInput-invalid,
.page-sku-2 .mantine-MultiSelect-invalid,
.page-submission-review .mantine-MultiSelect-invalid,
.page-sku-2 .mantine-DatePicker-invalid,
.page-submission-review .mantine-DatePicker-invalid,
.page-submission-review .mantine-Select-invalid,
.page-sku-2 .mantine-Select-invalid {
  border: 2px solid #f03e3e !important;
}

a {
  text-decoration: none !important;
}

.prod-dev-tabs .custom-paper {
  height: 100vh !important;
  max-height: calc(100vh - 150px);
  position: relative;
}

.fetch-skus-button {
  text-align: right;
}

.fetch-skus-button button {
  margin-bottom: 16px;
}

.navbar-brand {
  padding-bottom: 0px !important;
}

.close-button:hover {
  background-color: transparent;
}

.z-99 {
  z-index: 99;
}
.top-0 {
  top: 0;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.nav-left-button {
  left: 0px;
  border-radius: 0px 6px 6px 0px;
}
.nav-right-button {
  right: 0px;
  border-radius: 6px 0px 0px 6px;
}

.nav-right-button-active {
  right: 240px !important;
}

.nav-left-button-active {
  left: 240px !important;
}

.nav-left-button,
.nav-right-button {
  position: absolute;
  height: min-content;
  top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  z-index: 999;
  color: rgb(5, 5, 5);
  background: linear-gradient(90deg, #e3af32 0%, #f4e00f 100%);
}

.vertical-text {
  writing-mode: vertical-rl;
  padding: 3px;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 12px;
  min-height: 67px;
  text-align: center;
}

.nav-close-button {
  float: right;
  background: #ffffff;
  color: #e5b611;
  border-radius: 0%;
}

/* .prod-dev-tabs .mantine-Tabs-tabsListWrapper .mantine-Tabs-tabsList {
  width: 58vw;
  justify-content: flex-start;
  margin-left: 12vw;
  flex-wrap: unset;
  overflow: scroll;
}

@media only screen and (max-width: 1300px) {
  .prod-dev-tabs .mantine-Tabs-tabsListWrapper .mantine-Tabs-tabsList {
    width: 58vw;
    justify-content: flex-start;
    margin-left: 8vw;
    flex-wrap: unset;
    overflow: scroll;
  }
} */

.prod-dev-tabs .menu-tabs button {
  min-width: 159px;
}

.prod-dev-tabs .mantine-Tabs-body {
  padding-top: 16px;
}

.nav-close-button {
  position: absolute;
  width: 24px;
  margin-left: 238.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  z-index: 999;
  color: rgb(0, 0, 0);
  background: linear-gradient(90deg, #e3af32 0%, #f4e00f 100%);
  margin-right: -24px;
  margin-top: 0px;
  border-radius: 0px 6px 6px 0px;
}

.nav-close-button-workflow {
  position: absolute;
  margin-top: 0px;
  top: 0px;
  width: 24px;
  left: -24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  z-index: 999;
  color: black;
  background: linear-gradient(90deg, #e3af32 0%, #f4e00f 100%);
  border-radius: 6px 0px 0px 6px;
}

.select-all-checkbox svg {
  display: none;
}

#selection_placeholder {
  background-color: #ffefc5;
}
.page-productdevelopment .table-container {
  zoom: unset;
}

.prod-dev-table-header,
.prod-dev-table-data {
  width: 200px;
  padding: 10px !important;
  max-width: 300px;
  min-width: 300px;
  white-space: unset;
}

.prod-dev-table-header {
  position: sticky;
  background-color: #ffefc5;
}

.prod-dev-table-data {
  border-right: 1px solid #cdcdcd;
}

.prod-dev-accordion .mantine-Accordion-contentInner {
  padding: 0px !important;
}
.implement-table-header,
.implement-table-data {
  width: 200px;
  padding: 10px !important;
  white-space: unset;
  border-right: 1px solid #dee2e6;
}
.capatilize {
  text-transform: capitalize;
}

.export-to-csv,
.export-to-csv:hover {
  color: #fff;
}

.manually-closed {
  margin: 1rem 0 0.5rem 1rem;
  color: red;
  font-weight: 400;
}

@media only screen and (max-width: 812px) {
  .nav-close-button {
    margin-left: 199px;
  }
}

@media only screen and (max-width: 650px) {
  .nav-close-button {
    margin-left: 151px;
  }
}

.scope-unlock-status {
  background: linear-gradient(90deg, #f4e00f 0%, #e3af32 100%);
  border: 1px solid #fab005;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 19px;
  line-height: 1.4;
  text-align: center;
}

.white {
  color: #ffffff;
}

.blue {
  color: rgb(20, 20, 20);
}

.red {
  color: red;
}

.rfs-link {
  text-decoration: none;
}

.rfs-link:hover {
  color: white;
}

.pd-0,
.pd-next-0 > div {
  padding: 0;
}

.ovrflowAuto {
  overflow: auto;
}

.implementation-tabs .mantine-Tabs-tabsList {
  width: max-content;
}

.implementation-tabs .mantine-Tabs-tabsListWrapper {
  overflow-x: scroll;
}

.w100.confirm-modal .mantine-Modal-content {
  width: 90vw;
  flex: none !important;
}

.individual-steps-view-modal .mantine-Modal-content {
  width: 90rem !important;
  flex: none !important;
}

.individual-steps-view-modal .mantine-Container-root {
  width: 86rem !important;
  max-width: 86rem !important;
}

.limitTDWidth td {
  white-space: break-spaces !important;
}

.ean_numbers {
  white-space: break-spaces;
  min-width: 20rem;
}
.mwnone {
  max-width: none;
}

td.markets {
  overflow-x: auto;
  max-width: 350px;
}

.prod-dev-tabs-veam button {
  position: relative;
}

.dot {
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  right: 4px;
  top: 4px;
  border-radius: 50%;
  background: rgb(134, 134, 134);
  cursor: help;
  display: none;
}

.dot2 {
  background: rgb(243 255 16);
  background: -moz-radial-gradient(circle, rgba(221, 182, 83, 1) 0%, rgba(145, 106, 4, 1) 100%);
  background: -webkit-radial-gradient(circle, rgb(243 255 16) 0%, rgb(208 227 13) 100%);
  background: radial-gradient(circle, rgb(243 255 16) 0%, rgb(208 227 13) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ddb653",endColorstr="#916a04",GradientType=1);
}

.dot3 {
  background: rgb(157, 252, 157);
  background: -moz-radial-gradient(circle, rgba(157, 252, 157, 1) 0%, rgba(0, 161, 26, 1) 100%);
  background: -webkit-radial-gradient(circle, rgba(157, 252, 157, 1) 0%, rgba(0, 161, 26, 1) 100%);
  background: radial-gradient(circle, rgba(157, 252, 157, 1) 0%, rgba(0, 161, 26, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9dfc9d",endColorstr="#00a11a",GradientType=1);
}

.custom-mantine-Textarea-error {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  -webkit-text-decoration: none;
  text-decoration: none;
  word-break: break-word;
  color: #d09100;
}

.red-custom-mantine-Textarea-error {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  -webkit-text-decoration: none;
  text-decoration: none;
  word-break: break-word;
  color: red;
  margin-bottom: 16px;
  margin-top: -10px;
}

/** new css for upgrade **/
.mantine-Tabs-panel {
  padding-top: 10px;
}

.padding-0 {
  padding: 0;
}

.mantine-DatePickerInput-month {
  min-width: auto !important;
}

.accordion-innerContent .mantine-Accordion-content {
  padding: 5px 16px 16px;
}

/** new css for upgrade **/
.mantine-Tabs-panel {
  padding-top: 10px;
}

.padding-0 {
  padding: 0;
}

.mantine-DatePickerInput-month {
  min-width: auto !important;
}

.accordion-innerContent .mantine-Accordion-content {
  padding: 5px 16px 16px;
}

/** mantine-Paper-root **/
@media only screen and (max-width: 992px) {
  .mantine-Paper-root {
    width: 100% !important;
  }

  .accordion-innerContent .mantine-Accordion-content {
    padding: 5px 16px 16px;
  }
}

.w-full {
  width: 100%;
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 12px;
  transition: 0.3s background;
  border-radius: 8px;
}

body *::-webkit-scrollbar-thumb {
  background: #adb5bd;
  border-radius: 8px;
}

body *:hover::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: linear-gradient(90deg, #e3af32 0%, #f4e00f 100%);
}

.avic-table tfoot tr {
  background-color: #dfecff !important;
}
